import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div id="footer" className='relative flex-shrink-0 bg-gray-bg'>
            {/* <footer className='absolute bottom-0 z-[-1] flex-shrink-0'> */}
            <div className="container mx-auto flex flex-col items-center justify-between px-4 lg:px-6 mb-0">
                <div className='w-11/12 flex items-center justify-center py-8'>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        <div className="flex items-start gap-x-4">
                            <div className="w-full">
                                <Link to={"/"} className="">
                                    <img src="/images/villairis2.png" alt="" className="w-full" />
                                </Link>
                            </div>
                        </div>

                        <div className="md:col-span-2 w-full flex flex-col md:flex-row lg:flex-col space-x-0 md:space-x-8 lg:space-x-0 items-start text-blue-primary">
                            <div className='flex flex-col items-start mb-4'>
                                <p className='mb-0 text-sm font-bold'>Villa IRIS II</p>
                                <p className='mb-0 text-sm font-normal'>Sede Legale: Piazza Gozzano 1 – 10132 Torino (TO)</p>
                                <p className='mb-0 text-sm font-normal'>Sede Operativa: Carbignano, 34 - 10020 Verrua Savoia (TO)</p>
                                <p className='mb-0 text-sm font-normal'>P.IVA: 05389380014</p>
                                <p className='mb-0 text-sm font-normal'>Capitale Sociale: 25.822,00 i.v.</p>
                                <p className='mb-0 text-sm font-normal'>REA: TO-706629</p>
                                <p className='mb-0 text-sm font-normal'>PEC: villairis2@legalmail.it</p>
                            </div>

                            <div className='flex flex-wrap'>
                                <p className='mb-0 text-sm font-normal'>struttura accreditata con il servizio sanitario nazionale - Villa IRIS II</p>
                                <div className='flex flex-wrap gap-x-2 font-bold'>
                                    <Link to={"/amministrazione-trasparente"} className='mb-0 underline hover:cursor-pointer'>AMMINISTRAZIONE TRASPARENTE</Link>
                                    <p className='mb-0'>|</p>
                                    <Link to={"/privacy-policy"} className='mb-0 underline hover:cursor-pointer'>PRIVACY POLICY</Link>
                                    <p className='mb-0'>|</p>
                                    <Link to={"/cookie-policy"} className='mb-0 underline hover:cursor-pointer'>COOKIE POLICY</Link>
                                    <p className='mb-0'>|</p>
                                    <a href={"https://kaffeinabucket.s3.eu-west-3.amazonaws.com/camerlengo/PROGETTO+DI+GESTIONE.pdf"} target='_blank' className='mb-0 underline hover:cursor-pointer'>PROGETTO DI GESTIONE</a>
                                    {/* <Link to={"/"} className='mb-0 underline hover:cursor-pointer'>PROGETTO DI GESTIONE</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer