import React, { useEffect } from "react";

import { FaArrowRight, FaRegClock } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Home = () => {

  return (
    <div id="main" className="mt-0 mb-0 z-20 relative">
      {/* <!-- Hero Image --> */}
      <div className="flex flex-col items-center justify-between mb-0">
        <div className="flex w-full">
          <img
            loading="lazy"
            src="/images/header_vi2.png"
            className="w-full"
          />
        </div>
      </div>

      <div className="container mx-auto flex flex-col items-center justify-between px-4 lg:px-6 mb-0 z-30">
        {/* Header Logo */}
        <div className="absolute top-0 left-8 xl:left-24 z-20">
          <img
            loading="lazy"
            src="/images/villairis2.png"
            className="w-1/3 lg:w-1/2 xl:w-4/6 2xl:w-11/12"
          />
        </div>

        <div className="w-11/12 description pt-12 pb-24 border-b-2 border-blue-secondary">
          <p className="w-full mx-auto text-left text-blue-primary font-normal text-2xl lg:mt-0 lg:mx-0">
            Il complesso strutturale <span className="font-bold">Villa IRIS II</span> ospita una serie articolata di servizi gestiti direttamente:<br />
            un nucleo di Residenza Sanitaria Assistenziale (RSA) per anziani non autosufficienti
            prevalentemente di alta intensità assistenziale, distribuito su due piani autorizzati ad ospitare
            25 Ospiti ciascuno.
          </p>
          <p className="w-full mx-auto text-left text-blue-primary font-normal text-2xl lg:mt-0 lg:mx-0">
            Una Struttura Riabilitativa Psichiatrica 2.1 per adulti con diagnosi psichiatrica per 20 ospiti.
          </p>
        </div>

        <div className="w-11/12 flex flex-col-reverse justify-between gap-8 lg:gap-16 lg:flex-row lg:mb-0 mt-12">
          {/* <!-- Content Left --> */}
          <div data-aos="fade-right" data-aos-duration="1500" className="flex flex-col justify-between gap-4 lg:w-1/2">
            <div className="flex flex-col justify-start gap-4">
              <h1 className="text-blue-primary text-4xl font-bold text-right lg:w-11/12">
                VILLAIRIS II
              </h1>
              <p className="!leading-10 w-full mx-auto text-right text-blue-primary font-normal text-2xl underline underline-offset-8 lg:mt-0 lg:mx-0">
                Località Carbignano, 34<br />
                10020 Verrua Savoia (To)
              </p>
            </div>

            <div className="mt-8">
              <p className="text-3xl font-bold text-gray-text text-right">CONTATTI</p>

              <div className="flex flex-col justify-end">

                {/* <!-- Contact Info --> */}
                <div className="flex flex-col justify-start mt-4 space-y-6 text-xl md:space-y-0 md:space-x-4">
                  <p className="text-right text-2xl font-bold text-pink-primary">Amministrazione</p>
                  <a href="mailto:info@villairis2.srl" className="text-2xl font-medium text-blue-primary text-right no-underline">info@villairis2.srl</a>
                </div>
                {/* <!-- Contact Info --> */}
                <div className="flex flex-col justify-start mt-4 space-y-6 text-xl md:space-y-0 md:space-x-4">
                  <p className="text-right text-2xl font-bold text-pink-primary">Direzione</p>
                  <a href="mailto:direzione@villairis2.srl" className="text-2xl font-medium text-blue-primary text-right no-underline">direzione@villairis2.srl</a>
                </div>

              </div>
            </div>

            <div className="flex items-center justify-end mb-12">
              <a href="tel:0161.849.117" className="flex items-center justify-between space-x-4 px-3 py-2 rounded-lg border-2 border-green-primary overflow-hidden cursor-pointer">
                <img loading="lazy" src="/images/icons/phone.png" alt="phone-icon" className="h-12" />
                <p className="no-underline text-green-primary text-2xl font-black">0161.849.117</p>
              </a>
            </div>
          </div>

          {/* <!-- Content Right --> */}
          <div className="relative flex flex-col justify-start items-center gap-4 lg:w-1/2">
            <div className="flex space-x-4 w-full">
              {/* Box 1 */}
              <div data-aos="fade-up" data-aos-duration="1500" className="w-1/2 bg-blue-primary aspect-square p-4 pb-12">
                <img loading="lazy" src="/images/icons/clock.png" alt="clock-icon" className="h-12" />
                {/* <FaRegClock className="text-5xl text-white" /> */}
                <p className="text-left text-lg font-bold text-white mt-4">ACCESSO</p>
                <p className="text-left text-lg font-bold text-white mb-2">AI VISITATORI</p>
                <p className="text-left text-lg text-white">TUTTI I GIORNI</p>
                <div className="flex items-center space-x-2">
                  <p className="text-left text-lg text-white">08.00</p>
                  <FaArrowRight className="text-lg text-white" />
                  <p className="text-left text-lg text-white">20.00</p>
                </div>
              </div>
              {/* Box 2 */}
              <div data-aos="fade-up" data-aos-duration="1500" className="w-1/2 bg-gray-text aspect-square p-4 pb-12">
                <img loading="lazy" src="/images/icons/clock_blue.png" alt="clock-icon" className="h-12" />
                {/* <FaRegClock className="text-5xl text-blue-primary" /> */}
                <p className="text-left text-lg font-bold text-blue-primary mt-4">TELEFONO</p>
                <p className="text-left text-lg font-bold text-blue-primary mb-2">SEGRETERIA</p>
                <p className="text-left text-lg text-blue-primary">LUN / VEN</p>
                <div className="flex items-center space-x-2">
                  <p className="text-left text-lg text-blue-primary">08.30</p>
                  <FaArrowRight className="text-lg text-blue-primary" />
                  <p className="text-left text-lg text-blue-primary">13.00</p>
                </div>
                <div className="flex items-center space-x-2">
                  <p className="text-left text-lg text-blue-primary">14.00</p>
                  <FaArrowRight className="text-lg text-blue-primary" />
                  <p className="text-left text-lg text-blue-primary">15.30</p>
                </div>
              </div>
            </div>

            <div data-aos="fade-right" data-aos-duration="1500" className="flex w-full">
              <img loading="lazy" src="/images/foto3.png" alt="w-full object-contain" />
            </div>
          </div>
        </div>

        <div className="w-11/12 description pt-12 pb-16">
          <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-blue-primary font-normal text-2xl mb-4 lg:mx-0">
            Il complesso strutturale <span className="font-bold">Villa IRIS II</span> ospita una serie articolata di servizi gestiti direttamente:
          </p>
          <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-blue-primary font-normal text-2xl mb-4 lg:mx-0">
            L’edificio è stato costruito nel corso del 1700 e risultava composto da una ampia Villa
            Padronale, da un’antistante parco, da un edificio adibito a piccola cappella nonché da un
            ampio rustico. Sono del 1978 le prime trasformazioni e ristrutturazioni poi riprese nel 1990
            dall’attuale proprietà.
          </p>
          <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-blue-primary font-normal text-2xl mb-4 lg:mx-0">
            <span className="font-bold">Villa IRIS II</span> nacque il 1° febbraio 1991 a seguito della necessità di dover fornire assistenza
            continuativa a pazienti affetti da malattie mentali croniche non gestibili in maniera
            alternativa, ingigantita dalla progressiva ed obbligatoria chiusura sul territorio nazionale
            degli Ospedali Psichiatrici.
          </p>
          <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-blue-primary font-normal text-2xl mb-4 lg:mx-0">
            Per alcuni anni fu una struttura funzionante esclusivamente per malati mentali cronici
            di duplice provenienza: il nucleo originario, costituito prevalentemente da ex pazienti
            manicomiali, in cui la patologia mentale stava progressivamente scemando per dare ampio
            spazio a problematiche geriatrico-internistico ed assistenziali in generale; ed un secondo
            nucleo costituito da pazienti nuovi ingressi, più giovani e con patologia più florida, anche se
            ben controllata.
          </p>
          <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-blue-primary font-normal text-2xl mb-4 lg:mx-0">
            Con il passare degli anni i nuovi inserimenti si ridussero progressivamente e le richieste di
            assistenza e ricovero si spostarono nell’ambito dei pazienti anziani non autosufficienti.
          </p>
          <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-blue-primary font-normal text-2xl mb-4 lg:mx-0">
            Il progressivo invecchiamento della popolazione, con sempre maggior presenza di
            patologie croniche e di pazienti non autosufficienti suggerì la creazione di nuovi locali
            adatti ad ospitare pazienti non autosufficienti. Iniziarono così lunghe e complesse opere di
            ristrutturazione del nucleo originario. Nel Gennaio 1999 avvenne la trasformazione delle due
            realtà assistenziali (2 ali separate ma collegate e destinate alle 2 tipologie dei pazienti) in:<br />
            <span className="font-bold">Struttura riabilitativa psichiatrica 2.1</span> e <span className="font-bold">Residenza Sanitaria Assistenziale</span>.
            Con l’estate del 1999 la Direzione decise di dotare Villa IRIS II di un Sistema di Gestione Qualità.
          </p>
        </div>

        <div className="w-11/12 grid grid-cols-2 lg:grid-cols-4 gap-4 mb-16">
          <div data-aos="fade-right" data-aos-duration="1500" className="w-full border-2 border-gray-text bg-cover bg-center" style={{ backgroundImage: 'url("/images/casaservizi_vi2_0.png")' }}>
            <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/camerlengo/IRIS2_carta_servizi_web_doppia_pagina.pdf"><img src="/images/casaservizi_vi2_0.png" alt="" className="w-full opacity-0" /></a>
          </div>
          <div data-aos="fade-right" data-aos-duration="1500" className="w-full bg-cover bg-center" style={{ backgroundImage: 'url("/images/foto.png")' }}>
            <img src="/images/foto.png" alt="" className="w-full opacity-0" />
          </div>

          <div data-aos="fade-left" data-aos-duration="1500" className="col-span-2 w-full bg-cover bg-center" style={{ backgroundImage: 'url("/images/foto2.png")' }}>
            <img src="/images/foto2.png" alt="" className="w-full opacity-0" />
          </div>
        </div>

      </div>

      <div className="bg-blue-secondary">
        <div className="container mx-auto flex flex-col items-center justify-between px-4 lg:px-6 mb-0">
          <div className="flex items-center justify-center w-full">
            <div className="w-11/12 pt-12 pb-24">
              <p data-aos="fade-right" data-aos-duration="1500" className="w-full mx-auto text-left text-white font-normal text-2xl mt-6 mb-4 lg:mx-0">
                RESIDENZA <span className="font-bold">SANITARIA ASSISTENZIALE</span>
              </p>
              <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-black font-normal text-2xl mb-4 lg:mx-0">
                Ospitalità a tempo indeterminato per anziani non autosufficienti non assistibili a domicilio per motivi
                socio sanitari e che richiedono un livello di alta intensità assistenziale.<br />
                Tali persone vengono accolte presso i nuclei di Residenza Sanitaria Assistenziale (RSA).
              </p>
              <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-black font-normal text-2xl mb-0 lg:mx-0">
                La persona anziana e la persona interessata e/o la sua famiglia possono accedere al servizio:
              </p>
              <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-black font-normal text-2xl mb-0 lg:mx-0">1. rivolgendosi direttamente a Villa Iris II</p>
              <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-black font-normal text-2xl mb-4 lg:mx-0">2. in seguito alla valutazione dell’Unità di Valutazione Geriatrica (UVG) della propria ASL</p>

              <p data-aos="fade-right" data-aos-duration="1500" className="w-full mx-auto text-left text-white font-normal text-2xl mt-16 mb-4 lg:mx-0">
                <span className="font-bold">STRUTTURA RIABILITATIVA PSICHIATRICA 2.1</span>
              </p>
              <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-black font-normal text-2xl mb-4 lg:mx-0">
                Ospita pazienti con una diagnosi psichiatrica in regime residenziale per trattamenti
                terapeutico-riabilitativi a carattere estensivo con media intensità riabilitativa e assistenziale.<br />
                <span className="font-bold">Tali pazienti vengono accolti presso il nucleo SRP 2.1.</span>
              </p>
              <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-black font-normal text-2xl mb-0 lg:mx-0">
                La persona anziana e la persona interessata e/o la sua famiglia possono accedere al servizio:
              </p>
              <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-black font-normal text-2xl mb-0 lg:mx-0">1. rivolgendosi direttamente a Villa Iris II</p>
              <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-black font-normal text-2xl mb-4 lg:mx-0">2. in seguito alla valutazione dell’Unità di Valutazione Geriatrica (UVG) della propria ASL</p>

              <p data-aos="fade-right" data-aos-duration="1500" className="w-full mx-auto text-left text-white font-normal text-2xl mt-16 mb-4 lg:mx-0">
                SERVIZI <span className="font-bold">RESIDENZIALI PERSONALIZZATI</span>
              </p>
              <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-black font-normal text-2xl mb-4 lg:mx-0">
                Per garantire il rispetto dei principi e l’adeguatezza delle prestazioni assistenziali e delle cure, Villa
                Iris II considera quale principale strumento di lavoro il Piano Assistenziale Individualizzato (PAI) che
                pone l’ospite al centro di ogni attività e servizio erogato in RSA. Il Piano Terapeutico Riabilitativo
                Personalizzato in SRP 2.1..
              </p>

              <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-black font-normal text-2xl mb-4 lg:mx-0">
                <span className="font-bold">Piani assistenziali individualizzati (PAI) e Piano Terapeutico Riabilitativo (PTRP)</span>
                Per ogni ospite del nucleo RSA viene definito un Piano assistenziale individualizzato (PAI), per ogni
                ospite insierito in SRP 2.1 viene redatto un Piano terapeutico riabilitativo individualizzato, grazie ad una
                valutazione multidimensionale e multiprofessionale dell’Unità Valutativa Interna costituita dall’Equipe
                MultiDimensionale.<br />
                Si tratta, in entrambi i casi, della effettiva presa in carico della persona nella sua totalità e complessità
                dei bisogni e degli obiettivi riabilitativi e nell’accompagnamento lungo il percorso attuativo del piano
                stesso. Questo documento viene costantemente monitorato e riaggiornato, per garantire una costante
                appropriatezza delle
              </p>

              <p data-aos="fade-left" data-aos-duration="1500" className="w-full mx-auto text-left text-black font-normal text-2xl mb-4 lg:mx-0">
                <span className="font-bold">Villa Iris II</span>, tramite l’équipe multidisciplinare interna verifica l’andamento del progetto e verifica la
                coerenza tra il livello di intensità e complessità assistenziale e le esigenze clinico-assistenziali del
                residente, modificando il PAI, ogni volta che si ritenga necessario.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
