import { BrowserRouter } from "react-router-dom";
import BaseRoutes from "./routes";

import Footer from "./components/Footer";

function App() {
  return (
    <div className="overflow-x-hidden">
      <BrowserRouter>
        <BaseRoutes />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
